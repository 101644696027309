'use strict';

module.exports = function () {
    $(document).on('busLoading:init', function () {
        const loading = $('.searchTicketsLoading.js-searchTicketsLoading');
        loading.css({ display: 'block' });
        $('.ticket__tripsConnection--desktop').css({ 'z-index': '0' });
        $('.ticket__tag__wrap').parent().css({ 'z-index': '0' });
    });

    $(document).on('busLoading:stop', function () {
        const loading = $('.searchTicketsLoading.js-searchTicketsLoading');
        loading.css({ display: 'none' });
        $('.ticket__tripsConnection--desktop').css({ 'z-index': '3' });
        $('.ticket__tag__wrap').parent().css({ 'z-index': '2' });
    });

    $(document).on('seatLoading:init', function () {
        const loading = $('.seatLoading.js-seatLoading');
        loading.css({ display: 'block' });
        $('.ticket__tripsConnection--desktop').css({ 'z-index': '0' });
        $('.ticket__tag__wrap').parent().css({ 'z-index': '0' });
    });

    $(document).on('seatLoading:stop', function () {
        const loading = $('.seatLoading.js-seatLoading');
        loading.css({ display: 'none' });
        $('.ticket__tripsConnection--desktop').css({ 'z-index': '3' });
        $('.ticket__tag__wrap').parent().css({ 'z-index': '2' });
    });
};
