'use strict';

const defaults = {
    faqOptionsListClass: '.faq-options__list',
    faqAnswerListClass: '.faq-answers__list',
    faqOptionsItemsClass: '.faq-options__item',
    faqOptionsItemButton: '.faq-options__item > button',
    faqAnswersItem: '.faq-answers__item'
};

var currentButtonDataList = '';
var currentList = '';
var currentButton = '';

module.exports = {
    setOptions: function (options) {
        this.options = Object.assign({}, this.options, options);

        return this;
    },

    addActiveClass: function (elemToActUpon) {
        if (elemToActUpon === undefined || elemToActUpon === null || elemToActUpon === '') return;

        $(elemToActUpon).addClass('active');

        return this;
    },

    removeActiveClass: function (elemToActUpon) {
        if (elemToActUpon === undefined || elemToActUpon === null || elemToActUpon === '') return;

        $(elemToActUpon).removeClass('active');

        return this;
    },

    showList: function (elemToActUpon, listToActUpon) {
        if (elemToActUpon === undefined || elemToActUpon === null || elemToActUpon === '') return;

        this.addActiveClass(elemToActUpon);
        this.addActiveClass(listToActUpon);

        return this;
    },

    hideList: function (isItTrue) {
        if (isItTrue === undefined || isItTrue === null || isItTrue === '' || isItTrue === false) return;

        this.removeActiveClass(this.options.faqAnswersItem);
        this.removeActiveClass(this.options.faqAnswerListClass);
        this.removeActiveClass(this.options.faqOptionsItemButton);

        return this;
    },

    bindEvHandler: function (options) {
        if (options === undefined || options === null || options === '') return;

        $('body').on('click', this.options.faqOptionsListClass, function (ev) {
            if (!$(ev.target).hasClass('btn-secondary')) return;

            currentButton = $(ev.target);
            currentButtonDataList = currentButton.data('button');
            currentList = $(this.options.faqAnswerListClass + '[data-list=' + currentButtonDataList + ']');

            this.hideList(true).showList(currentButton, currentList);
        }.bind(this));

        $('body').on('click', this.options.faqAnswersItem, function (ev) {
            if (!$(ev.target).hasClass('faq-answers__title')) return;

            if ($(ev.target).parent().hasClass('active')) {
                this.removeActiveClass($(ev.target).parent());
            } else {
                this.removeActiveClass(this.options.faqAnswersItem);
                this.addActiveClass($(ev.target).parent());
            }
        }.bind(this));

        return this;
    },

    activeButtonByIndex: function (index) {
        $('body').find(this.options.faqOptionsItemButton).eq(index).trigger('click');
    },

    init: function () {
        $('.faq-answers__description-block').children('p').addClass('faq-answers__description-text');

        this.setOptions(defaults).bindEvHandler(defaults);

        this.activeButtonByIndex(0);
    }
};
