var moment = require('moment')

var getDateUSA = function(ptbrDate) {
    var date = ptbrDate.split('/');
    return date[2] + '-' + date[1] + '-' + date[0];
};

var getMillisecondsFromPTBRDate = function(ptbrDate) {
    var date = new Date(getDateUSA(ptbrDate));
    return date.getTime();
};

var copyAndMerge = function(target, source) {
    var emptyObject = {};

    for(var key in source) {
        emptyObject[key] = source[key];

        if(target.hasOwnProperty(key)) {
            emptyObject[key] = target[key];
        }
    }

    return emptyObject;
};

var mixin = function(target, source) {
    for(var key in source) {
        if(source.hasOwnProperty(key)) {
            target[key] = source[key];
        }
    }
};

var isMobile = function() {
    return $(window).width() < 800;
};

module.exports = {
    getDateUSA: getDateUSA,
    getMillisecondsFromPTBRDate: getMillisecondsFromPTBRDate,
    copyAndMerge: copyAndMerge,
    mixin: mixin,
    isMobile: isMobile
};