module.exports = function () {
    var variables = $('#variables').data();
    var root = document.documentElement;

    root.style.setProperty('--primary', variables.primary);
    root.style.setProperty('--secondary', variables.secondary);
    root.style.setProperty('--third', variables.third);
    root.style.setProperty('--highlight', variables.highlight);
    root.style.setProperty('--imageBg', 'url("' + variables.image + '")');
};
