module.exports = {
    init: function () {
        $('.aligner-block-pre').find('.row').prepend('<div class="progress-wrapper"><div class="progress"></div></div>');
        var maxProgressHeight = $('.aligner-block-pre').css('height');
        $('div.progress').css('max-height', maxProgressHeight);
        var lineColor = $('.line-color').val();
        $('div.progress').addClass(lineColor);

        var firstCircle = $('.circle').length ? $('.circle').first().offset().top : 0;
        var progressLineHeight = $('.circle').length ? $('.circle').last().offset().top - firstCircle : 0;
        $('.progress-wrapper').css('height', progressLineHeight);
        $('.progress-wrapper').css('top', (firstCircle - 100) + 'px');
        $('div.progress').css('max-height', progressLineHeight);

        $(document).on('scroll', function () {
            // Different scroll behaviors mobile x desktop
            var pixels;
            var pageHeight;
            var progress;
            if (window.innerWidth > 1000) {
                pixels = $(document).scrollTop();
                pageHeight = $(document).height() - $(window).height();
                progress = (120 * pixels) / pageHeight;

                $('div.progress').css('height', progress + '%');
            }
            if (window.innerWidth < 1000) {
                pixels = $(document).scrollTop();
                pageHeight = $(document).height() - $(window).height();
                progress = (220 * pixels) / pageHeight;

                $('div.progress').css('height', progress + '%');
            }
        });

        $('.arrow').each(function (idx, el) {
            var parentHeight = Number($(el).parent().css('height').replace('px', ''));
            $(el).css('top', ((parentHeight / 2.45) * -1));
        });
    }
};
