'use strict';

function show(id, bgcolor) {
    var modal = $(id);
    modal.modal('show');

    var color = bgcolor || 'rgba(0,0,0,0.5)';

    modal.addClass('show');
    modal.css({
        display: 'block',
        'z-index': '100',
        'background-color': color,
        transition: 'opacity 300ms ease-in-out',
        'overflow-y': 'auto !important'
    });

    $('html').css('overflow-y', 'hidden !important');
    $('.modal-backdrop').css('display', 'none');
}

function hide(id) {
    var modal = $(id);
    modal.modal('hide');

    modal.removeClass('show');
    modal.removeAttr('style');
    $('.modal-backdrop').css('display', 'none');
    $('html').css('overflow-y', 'auto !important');
}

function hideError() {
    var modal = $('#errorModal');
    modal.modal('hide');
}

/**
 * Displays an error modal with specified details.
 * @param {Object} options - Object containing error details.
 * @param {string} options.message - Error message to display.
 * @param {string} options.title - Title of the error modal.
 * @param {boolean} [options.btnHome] - Flag to display a button to go home.
 * @param {string} [options.link] - Link to navigate when clicked.
 * @param {string} [options.buttonText] - Text to display on the button.
 * @param {boolean} [options.clearCart] - Flag to indicate clearing the cart.
 * @param {boolean} [options.showProgress] - Flag to indicate showing progress bar.
 * @param {number} [options.redirectTime] - Time to redirect to next page.
 *
 */
function showError({ message, title, btnHome, link, buttonText, clearCart, showProgress, redirectTime }) {
    var modal = $('#errorModal');

    modal.find('.modal-error-message').html(message.replace('Error: ', '').replaceAll('/n', '</br>'));
    modal.find('.modal-error-title').html(title);
    modal.find('.modal-error-close').on('click', function () {
        hideError();
    });

    if (link || buttonText) {
        modal.find('.goToLink').attr('href', link);
        modal.find('.goToLink').attr('target', '_blank');

        modal.find('.goToLink').html(buttonText);
        modal.find('.goToLink').toggleClass('hidden');

        modal.find('.goToLink').on('click', function () {
            hideError();
            $('.searchTicketsLoading__subTitle').addClass('d-none');
            $('body').trigger('checkoutLoading:init');
        });
    }

    if (showProgress) {
        modal.find('.modal-footer').addClass('d-none');
        modal.find('.close-modal').addClass('hidden');

        modal.find('.progress').removeClass('d-none');
        $('.bg-progress').animate({
            width: '100%'
        }, redirectTime);
    }

    if (redirectTime) {
        modal.attr('data-backdrop', 'static');
        setTimeout(function () {
            window.location.href = link;
        }, redirectTime);
    }

    if (clearCart) {
        modal.find('.close-modal').on('click', function () {
            $('body').find('#removeAllProductModal').find('.cart-delete-confirmation-btn').click();
            hideError();
        });
    }

    if (btnHome) {
        modal.find('.goToLink').toggleClass('hidden');
        modal.find('.close-modal').remove();
        modal.attr('data-backdrop', 'static');
    }

    modal.modal('show');
}

module.exports = {
    show: show,
    hide: hide,
    showError: showError,
    hideError: hideError
};
