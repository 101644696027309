module.exports = function () {
    $('.mask-cpf').mask('000.000.000-00');
    $('.mask-rg').mask('00.000.000-0');
    $('.mask-cnpj').mask('00.000.000/0000-00');
    $('.mask-cel').mask('(00) 00000-0000');
    $('.mask-phone').mask('(00) 0000-0000');
    $('.mask-cep').mask('00000-000');
    $('.mask-date').mask('00/00/0000');
    $('.mask-name').mask('A', {
        translation: {
            A: { pattern: /[a-zA-Zà-úÀ-Ú ]/, recursive: true }
        }
    });
};
