var showMessageBackDateValidade = function(inputOut, message, className) {
    var $inputOut = $(inputOut);

    $inputOut.addClass(className);
    $inputOut.next('.invalid-feedback').text(message);
};

module.exports = {
    showMessageBackDateValidade: showMessageBackDateValidade
};
