module.exports = {
    filterCards: function () {
        $('.state-select').on('change', function () {
            var cards = $('.pdv-card');
            var input = $(this).val();
            console.error('🚀 ~ file: pdv.js:6 ~ $ ~ input:', input);

            cards.each(function () {
                var estado = $(this).data('state');

                if (input === 'all' || input === estado) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            });
        });
    }
};
