var moment = require('moment');
var TYPES = require('./datepicker.types');
var utils = require('./datepicker.utils');
var validations = require('./datepicker.validations');

/**
 * Constructor that's instantiate a datepicker wrapper
 * @param {HTMLInputElement} inputIn Input to handle the In Date
 * @param {HTMLInputElement} inputOut Input Element to handle the Out Date
 * @param {Object} optionsIn Options to customize the datepickers based on datepickerrange
 * @param {Object} optionsOut Options to customize the datepickers based on datepickerrange
 */
function DatePickerCore(inputIn, inputOut, optionsIn, optionsOut) {
    this.$inputIn = $(inputIn);
    this.$inputOut = $(inputOut);
    this.$document = $(document);
    this.optionsIn = optionsIn;
    this.optionsOut = optionsOut;
    this.autoApplyIsActive = false;
    this.isInDateChoiced = false;

    this.initialize();
}

/**
 * A Static Initialize Methods that prevents the user to forgot the new keyword
 * @param {Object Property -> HTMLInputElement} inputIn Input to handle the In Date
 * @param {Object Property -> HTMLInputElement} inputOut Input Element to handle the Out Date
 * @param {Object Property -> Object} optionsIn Options to customize the datepickers based on datepickerrange
 * @param {Object Property -> Object} optionsOut Options to customize the datepickers based on datepickerrange
 */
DatePickerCore.initialize = function(options) {
    return new DatePickerCore(
        options.inputIn,
        options.inputOut,
        options.optionsIn,
        options.optionsOut
    );
};

/**
 * Initialize DatePickerCore
 */
DatePickerCore.prototype.initialize = function() {
    this.mountByTarget(TYPES.TARGETS.INPUT_IN);
    this.mountByTarget(TYPES.TARGETS.INPUT_OUT);

    this.$inputInContainer = $(TYPES.SELECTORS.IN_CONTAINER);
    this.$inputOutContainer = $(TYPES.SELECTORS.OUT_CONTAINER);

    this.removeAutoApply();
    this.clearAll();
    this.loadEvents();
};

DatePickerCore.prototype.loadEvents = function() {
    if(!this.$events) {
        this.$events = {
            onDatePickerCancel: this.onDatePickerCancel.bind(this),
            onDatePickerShow: this.onDatePickerShow.bind(this),
            onDatePickerHide: this.onDatePickerHide.bind(this),
            onDatePickerCancel: this.onDatePickerCancel.bind(this)
        };
    }

    this.$inputIn.on(TYPES.EVENTS.CANCEL, this.$events.onDatePickerCancel);
    this.$inputIn.on(TYPES.EVENTS.SHOW, this.$events.onDatePickerShow);
    this.$inputIn.on(TYPES.EVENTS.HIDE, this.$events.onDatePickerHide);
    this.$inputOut.on(TYPES.EVENTS.SHOW, this.$events.onDatePickerShow);
    this.$inputOut.on(TYPES.EVENTS.CANCEL, this.$events.onDatePickerCancel);
};

DatePickerCore.prototype.removeEvents = function() {
    if(!this.$events) return;

    this.$inputIn.off(TYPES.EVENTS.CANCEL, this.$events.onDatePickerCancel);
    this.$inputIn.off(TYPES.EVENTS.SHOW, this.$events.onDatePickerShow);
    this.$inputIn.off(TYPES.EVENTS.HIDE, this.$events.onDatePickerHide);
    this.$inputOut.off(TYPES.EVENTS.SHOW, this.$events.onDatePickerShow);
    this.$inputOut.off(TYPES.EVENTS.CANCEL, this.$events.onDatePickerCancel);
};

/**
 * Get the datePickerElement based on target In|Out
 * @param {String} target In|Out
 */
DatePickerCore.prototype.getDatePickerEl = function(target) {
    return $(this['options' + target].parentEl).children();
};

/**
 * Get the datePickerOptions based on target In|Out
 * @param {String} target In|Out
 */
DatePickerCore.prototype.getDatePickerOptions = function(target) {
    return this['options' + target];
};

/**
 * Mount the datePicker given a target
 * @param {String} target In|Out
 */
DatePickerCore.prototype.mountByTarget = function(target) {
    if (!this.hasPlugin(target)) {
        return this['$input' + target].daterangepicker(this['options' + target]);
    }
};

/**
 * Detroy the datePicker given a target
 * @param {String} target In|Out
 */
DatePickerCore.prototype.detroyByTarget = function(target) {
    if (!this.hasPlugin(target)) return;
    this['$input' + target].data('daterangepicker').remove();
};

DatePickerCore.prototype.hasPlugin = function(target) {
    return this['$input' + target].data('daterangepicker');
};

/**
 * Clear all inputs
 */
DatePickerCore.prototype.clearAll = function() {
    this.clear(TYPES.TARGETS.INPUT_IN);
    this.clear(TYPES.TARGETS.INPUT_OUT);
};

/**
 * Clear a single inputs based on target
 * @param {String} target In|Out
 */
DatePickerCore.prototype.clear = function(target) {
    this['$input' + target].val('');
};

/**
 * Show a single picker based on target
 * @param {String} target In|Out
 */
DatePickerCore.prototype.show = function(target) {
    this['$input' + target].trigger('click');
};

DatePickerCore.prototype.showIn = function(target) {
    this.show(TYPES.TARGETS.INPUT_IN);
};

DatePickerCore.prototype.showOut = function(target) {
    this.show(TYPES.TARGETS.INPUT_OUT);
};

DatePickerCore.prototype.getTargetByElement = function(element) {
    return element === this.$inputOut.get(0) ? 'Out' : 'In';
};

/**
 * Toogle Auto Apply
 */
DatePickerCore.prototype.removeAutoApply = function() {
    this.autoApplyIsActive = false;

    this.getDatePickerEl(TYPES.TARGETS.INPUT_IN).removeClass(TYPES.CLASSES.AUTO_APPLY);
    this.getDatePickerEl(TYPES.TARGETS.INPUT_OUT).removeClass(TYPES.CLASSES.AUTO_APPLY);
};

DatePickerCore.prototype.onDatePickerCancel = function(event) {
    $(event.target).val('');
};

DatePickerCore.prototype.onDatePickerShow = function(event, picker) {
    var inputDOM = event.target;
    var inputValue = $(inputDOM).val();
    if (inputValue) return;
    $(inputDOM).val(this.getDatePickerOptions(this.getTargetByElement(inputDOM)).minDate);
};

DatePickerCore.prototype.changeMinDateFromOptions = function(target, dateString) {
    this.optionsOut.minDate = moment(utils.getDateUSA(dateString)).format('DD/MM/YYYY');
};

DatePickerCore.prototype.onDatePickerHide = function(event, picker) {
    var inputOutValue = this.$inputOut.val();

    if (event.target === this.$inputIn.get(0)) {
        this.changeMinDateFromOptions(TYPES.TARGETS.INPUT_OUT, $(event.target).val());
        this.removeEvents();
        this.detroyByTarget(TYPES.TARGETS.INPUT_OUT);
        this.mountByTarget(TYPES.TARGETS.INPUT_OUT);
        this.loadEvents();
        this.removeAutoApply();

        if (inputOutValue === '') this.clear(TYPES.TARGETS.INPUT_OUT);
    }
};

module.exports = DatePickerCore;
