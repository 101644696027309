var utils = require('../../../scripts/util/utils');

function randomDestination() {
    var url = $('#product-data').data('url');
    var origem = $('#product-data').data('origem');
    var ulrSearch = $('#product-data').data('url-search');

    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        success: function (data) {
            var destinations = data.destinations;

            destinations.forEach(function (destination, index) {
                var html = '<a class="col-12 col-md-5 m-2 destination-route' + (index > 15 ? ' d-none ' : '') + '"  target="_blank" href="' + ulrSearch + '&origin=' + destination.id + '">' +
                    utils.formatCityName(destination.name, true) + ' - ' + destination.uf + ' para ' + origem +
                '</a>';

                $('.destination-routes').append(html);
            });
        },
        error: function (error) {
            console.error(error);
        }
    });
}


module.exports = {
    onReady: function () {
        $('.destination-routes').ready(function () {
            if( $('.destination-routes').length ){
                randomDestination();
            }
        });

        $('.destination-route').ready(function () {
            $('.destination-routes__button').on('click', function () {
                $('.destination-route.d-none:lt(10)').removeClass('d-none');
            });
        });
    }
};
