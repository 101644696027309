var DatePickerCore = require('./datepicker.core');
var DatePickerMobile = require('./datepicker.mobile');

var validations = require('./datepicker.validations');
var errors = require('./datepicker.errors');
var utils = require('./datepicker.utils');
var TYPES = require('./datepicker.types');

function DatePicker() {}

DatePicker.validations = validations;
DatePicker.errors = errors;
DatePicker.utils = utils;
DatePicker.TYPES = TYPES;

DatePicker.initialize = function(options) {
    var instance = utils.isMobile()
        ? DatePickerMobile.initialize(options)
        : DatePickerCore.initialize(options);

    DatePicker.setInstance(options.id, instance);

    return instance;
};

/**
 * Save instances to reuse later
 * @param {String} key instance identifier
 * @param {DatePicker} instance instace of DatePicker
 */
DatePicker.setInstance = function(key, instance) {
    var instanceHash = DatePicker.instanceHash || {};
    if (instanceHash[key]) return;
    instanceHash[key] = instance;
    DatePicker.instanceHash = instanceHash;
};

DatePicker.getInstance = function(key) {
    if (!DatePicker.instanceHash && !DatePicker.instanceHash[key]) return;
    return DatePicker.instanceHash[key];
};

module.exports = DatePicker;