/**
 * Group elements from an array by a common key in each object
 * @param {Array} arr The array
 * @param {*} criteria A function or a property of the objects from array
 */
var groupBy = function (arr, criteria) {
    return arr.reduce(function (objParam, item) {
        var obj = objParam;
        // Check if the criteria is a function to run on the item or a property of it
        var key = typeof criteria === 'function' ? criteria(item) : item[criteria];

        // If the key doesn't exist yet, create it
        if (!Object.hasOwnProperty.call(obj, key)) {
            obj[key] = [];
        }

        // Push the value to the object
        obj[key].push(item);

        // Return the object to the next item in the loop
        return obj;
    }, {});
};

/**
 * Intersect the content of n arrays
 * @param {Array} args Array of arrays to be intersected
 */
var intersect = function (args) {
    var result = [];
    var lists;

    if (args.length === 1) {
        lists = args[0];
    } else {
        lists = args;
    }

    for (var i = 0; i < lists.length; i++) {
        var currentList = lists[i];

        for (var y = 0; y < currentList.length; y++) {
            var currentValue = currentList[y];

            if (result.indexOf(currentValue) === -1) {
                if (lists.filter(function (obj) { return obj.indexOf(currentValue) == -1; }).length == 0) {
                    result.push(currentValue);
                }
            }
        }
    }

    return result;
};

/**
 * Convert a string to a Date object
 * @param {String} dateString the string input
 * @param {boolean} isDateTime convert the 'HH:mm' time part
 */
var stringToDate = function (dateString, isDateTime) {
    var calendar = new dw.util.Calendar();

    try {
        calendar.parseByFormat(dateString, 'yyyy-MM-dd' + (isDateTime ? ' HH:mm' : ''));
    } catch (err) {
        calendar.parseByFormat(dateString, 'dd/MM/yyyy' + (isDateTime ? ' HH:mm' : ''));
    }

    return calendar.getTime();
};

/**
 * Convert a string to a location object
 * @param {String} locationString the string input
 */
var stringToLocation = function (locationString) {
    var location = {
        city: null,
        uf: null
    };

    if (!empty(locationString)) {
        // Search for '******, uf' format
        if (locationString.length >= 4 && locationString[locationString.length - 4] == ',') {
            location.city = locationString.substring(0, locationString.length - 4);
            location.uf = locationString.substring(locationString.length - 2, locationString.length);
        } else {
            location.city = locationString;
        }
    }

    return location;
};

/**
 * Return a total hours between two dates
 * @param {Date} dateNow Date present
 * @param {Date} dateFuture Date in future
 */
var totalHoursMinutesSeconds = function (dateNow, dateFuture) {
    var seconds = Math.floor((dateFuture - (dateNow)) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);

    hours -= (days * 24);
    minutes = minutes - (days * 24 * 60) - (hours * 60);
    seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

    return {
        hours: hours,
        minutes: minutes,
        seconds: seconds
    };
};

/**
 * Verifica se a string do array com as palavras da cidade contém a preposição de/do/da.
 * @param {string} city A cidade sendo analisada. Ex.: "AGUAS DE SAO PEDRO, SP"
 * @returns {boolean} true || false
 */
function checkPreposition(city) {
    var regex = /\b(da|de|do)\b/i;
    return regex.test(city);
}

/**
 * Utilizada na página de buscas (ex.: Águas de São Pedro --> São Pedro).
 * Nas páginas de auto-aviação, o nome da cidade é inserido e deve retornar de forma diferente, com um hífen (código encapsulado no 'if').
 * @param {string} city A cidade sendo formatada. Ex.: "AGUAS DE SAO PEDRO, SP" OU "AGUAS DE SAO PEDRO - SP"
 * @param {boolean} notDefaultReturn Caso exista, a função retornará o nome da cidade sem o UF. Também é usada caso o valor do parâmetro 'city' venha com '-' ao invés de ','
 * @returns {string} City name formatted without the UF. Ex.: "Aguas de Sao Pedro, SP" OU "Aguas de Sao Pedro"
 */
function formatCityName(city, notDefaultReturn) {
    // eslint-disable-next-line no-useless-escape
    var cityName = city.toLowerCase().replace(/(^\w{1})|(\s+\w{1})|(\(+\w{1})|(\.+\w{1})|(\-+\w{1})|([._:-]+\s+\w{2})/g, function (a) {
        return a.toUpperCase();
    }).split(',');

    if (checkPreposition(cityName[0])) {
        var formattedCityName = '';
        var cityNameArrayFormatted = [];
        var cityNameArray = cityName[0].split(' ');
        for (var i = 0; i < cityNameArray.length; i++) {
            if (checkPreposition(cityNameArray[i])) {
                cityNameArrayFormatted.push(cityNameArray[i].toLowerCase());
            } else {
                cityNameArrayFormatted.push(cityNameArray[i]);
            }
        }
        formattedCityName = cityNameArrayFormatted.join(' ');

        if (notDefaultReturn) return formattedCityName;
        return formattedCityName.concat(',', cityName[1].toUpperCase());
    }

    if (notDefaultReturn) return cityName[0];
    return cityName[0].concat(',', cityName[1].toUpperCase());
}

module.exports = {
    groupBy: groupBy,
    intersect: intersect,
    stringToDate: stringToDate,
    stringToLocation: stringToLocation,
    totalHoursMinutesSeconds: totalHoursMinutesSeconds,
    formatCityName: formatCityName,
    checkPreposition: checkPreposition
};
