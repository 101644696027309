module.exports = {
    init: function () {
        $('.navbar-toggler').on('click', function () {
            var menu = $(this).data('target');
            $(menu).toggle();
        });
        $(document).ready(function(){
            window.zE('messenger:on', 'open', function() {
                document.querySelector('.js-gratuidade-close').classList.remove('hide');
            });

            window.zE('messenger:on', 'close', function() {
                document.querySelector('.js-gratuidade-close').classList.add('hide');
            });

        })

        if (document.querySelector('.Home-Show') || document.querySelector('.Default-Start')) {
            window.addEventListener("Tracker Ready", function () {
                $(window).trigger('reservamos:interestInHome');
            });
        }
    }
};
