'use strict';

const analytics = require("@reservamos/browser-analytics");

module.exports = {
    startReservamos: function () {
        $(window).on('load', function () {
            const reservamosData = JSON.parse($('[name="reservamos_data"]').val());
            if(reservamosData.active) {
                console.info('%c   RESERVAMOS started   ', 'background: #67ba5b; color: #fff');
                analytics.init({
                    mixpanelToken: reservamosData.mixpanelToken,
                    identificationKey: reservamosData.identificationKey,
                    debug: reservamosData.debug,
                    isSandbox: reservamosData.isSandbox,
                    identifyProxyUrl: reservamosData.identifyProxyUrl,
                    mixpanelProxyUrl: reservamosData.mixpanelProxyUrl,
                })
            }
        })
    },
    interestInHome: function () {
        $(window).on('load', function () {
            $(window).on('reservamos:interestInHome', function () {
                const reservamosData = JSON.parse($('[name="reservamos_data"]').val());
                if(reservamosData.active) {
                    const deviceType = window.deviceCheck()
                    analytics.track.interestInHome({
                        product: deviceType,
                    })
                }
            });
        })

    },
    search: function () {
        $(window).on('load', function () {
            $(window).on('reservamos:search', function (_event, data) {
                const reservamosData = JSON.parse($('[name="reservamos_data"]').val());
                if(reservamosData.active) {
                    const deviceType = window.deviceCheck();
                    data.product = deviceType;
                    analytics.track.search(data);
                }
            });
        })
    },
    viewResults: function () {
        $(window).on('load', function () {
            $(window).on('reservamos:viewResults', function (_event, data) {
                const reservamosData = JSON.parse($('[name="reservamos_data"]').val());
                if(reservamosData.active) {
                    const deviceType = window.deviceCheck();
                    data.product = deviceType;
                    analytics.track.viewResults(data);
                }
            });
        })
    },
    identify: function () {
        $(window).on('load', function () {
            $(window).on('reservamos:identify', function (_event, data) {
                const reservamosData = JSON.parse($('[name="reservamos_data"]').val());
                if(reservamosData.active) {
                    let userId = data.userId
                    delete data.userId

                    analytics.identify(
                        userId,
                        data
                    );
                }
            });
        })
    },
    seatChange: function () {
        $(window).on('load', function () {
            $(window).on('reservamos:seatChange', function (_event, data) {
                const reservamosData = JSON.parse($('[name="reservamos_data"]').val());
                if(reservamosData.active) {
                    const deviceType = window.deviceCheck();
                    data.product = deviceType;
                    analytics.track.seatChange(data);
                }
            });
        })
    },
    interestInSearch: function () {
        $(window).on('load', function () {
            $(window).on('reservamos:interestInSearch', function (_event, data) {
                const reservamosData = JSON.parse($('[name="reservamos_data"]').val());
                if(reservamosData.active) {
                    const deviceType = window.deviceCheck();
                    const prop = {}
                    prop.product = deviceType;
                    analytics.track.interestInSearch(prop, data);
                }
            });
        })
    },
    pickedDeparture: function () {
        $(window).on('load', function () {
            $(window).on('reservamos:pickedDeparture', function (_event, data) {
                const reservamosData = JSON.parse($('[name="reservamos_data"]').val());
                if(reservamosData.active) {
                    const deviceType = window.deviceCheck();
                    data.product = deviceType;
                    delete data.meta
                    analytics.track.pickedDeparture(data);
                }
            });
        })
    },
    passengersCreated: function () {
        $(window).on('load', function () {
            $(window).on('reservamos:passengersCreated', function (_event, data) {
                const reservamosData = JSON.parse($('[name="reservamos_data"]').val());
                if(reservamosData.active) {
                    const deviceType = window.deviceCheck();
                    data.product = deviceType;
                    delete data.meta
                    analytics.track.passengersCreated(data);
                }
            });
        })
    },
    paymentAttempt: function () {
        $(window).on('load', function () {
            $(window).on('reservamos:paymentAttempt', function (_event, data) {
                const reservamosData = JSON.parse($('[name="reservamos_data"]').val());

                if(reservamosData.active) {
                    const deviceType = window.deviceCheck();
                    data.product = deviceType;
                    delete data.meta
                    analytics.track.paymentAttempt(data);
                }
            });
        })
    },
    getDistinctId: function () {
        $(window).on('load', function () {
            $(window).on('reservamos:getDistinctId', function () {
                const reservamosData = JSON.parse($('[name="reservamos_data"]').val());
                if(reservamosData.active) {
                    const distinctId = analytics.identifiers.getDistinctId();
                    $('[name="reservamos_data"]').data('distinct', distinctId)
                }
            });
        })
    },
    getFingerprintId: function () {
        $(window).on('load', function () {
            $(window).on('reservamos:getFingerprintId', function () {
                const reservamosData = JSON.parse($('[name="reservamos_data"]').val());
                if(reservamosData.active) {
                    const fingerprintId = analytics.identifiers.getFingerprintId();
                    $('[name="reservamos_data"]').data('fingerprint', fingerprintId)
                }
            });
        })
    },
    purchaseCanceled: function () {
        $(window).on('load', function () {
            $(window).on('reservamos:purchaseCanceled', function (_event, data) {
                const reservamosData = JSON.parse($('[name="reservamos_data"]').val());

                if(reservamosData.active) {
                    const deviceType = window.deviceCheck();
                    data.product = deviceType;
                    const meta = data.meta
                    delete data.meta
                    analytics.track.purchaseCanceled(data, meta);
                }
            });
        })
    },
};
