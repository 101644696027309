var gLightbox = require('glightbox');

module.exports = {
    init: function () {
        gLightbox({
            selector: '.lightboxGallery',
            touchNavigation: true,
            keyboardNavigation: true,
            draggable: true,
            autoplayVideos: true,
            closeOnOutsideClick: true,
            preload: false

        });

        gLightbox({
            selector: '.lightboxVideo',
            plyr: {
                config: {
                    ratio: '16:9',
                    muted: false,
                    hideControls: true,
                    youtube: {
                        noCookie: true,
                        rel: 0,
                        showinfo: 0,
                        iv_load_policy: 3
                    }
                }
            }
        });
    }
};
