module.exports = {
    init: function () {
    $(window).on('load', function () {
        $('.slider-home-wrapper').slick({
            dots: false,
            infinite: true,
            speed: 500,
            fade: true,
            autoplay: true,
            cssEase: 'linear'
        });

        $('.slider-gallery-wrapper').slick({
            infinite: false,
            speed: 400,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        arrows: false,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        arrows: false,
                        slidesToScroll: 1
                    }
                }
            ]
        });

        $('.slider-destination').slick({
            infinite: false,
            speed: 400,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        arrows: false,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        arrows: false,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    });
    }
};
