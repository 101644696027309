window.isWebView = function () {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
        return userAgent.includes('wv') || (window.location.origin.includes('file://') && !userAgent.includes('Chrome'));
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return !(userAgent.includes('Safari') || userAgent.includes('Chrome'));
    }

    return false;
};