window.deviceCheck = function () {
    const isWebView = window.isWebView();
    const isMobile = window.mobileCheck();

    let device = 'web';

    if(isWebView) {
        device = 'app';

    } else if(isMobile) {
        device = 'web-mobile';
    }

    return device;
};
