var DatePickerCore = require('./datepicker.core');

var TYPES = require('./datepicker.types');

function DatePickerMobile(inputIn, inputOut, optionsIn, optionsOut) {
    DatePickerCore.call(this, inputIn, inputOut, optionsIn, optionsOut);
}

DatePickerMobile.prototype = Object.create(DatePickerCore.prototype);

/**
 * Static Initialize
 * @param {*} options
 */
DatePickerMobile.initialize = function(options) {
    return new DatePickerMobile(
        options.inputIn,
        options.inputOut,
        options.optionsIn,
        options.optionsOut
    );
};

DatePickerMobile.prototype.initialize = function() {
    DatePickerCore.prototype.initialize.call(this);

    this.setMobileContainer(TYPES.TARGETS.INPUT_IN);
    this.setMobileContainer(TYPES.TARGETS.INPUT_OUT);

    this.insertBackButton();
};

/**
 * Get DatePicker Container Element
 * @param {String} target In|Out
 */
DatePickerMobile.prototype.getContainer = function(target) {
    return $(TYPES.SELECTORS.WRAPPER).find(TYPES.SELECTORS[target.toUpperCase() + '_CONTAINER_MOBILE']);
};

/**
 * Set DatePicker Container Element
 * @param {String} target In|Out
 */
DatePickerMobile.prototype.setMobileContainer = function(target) {
    return this.getContainer(TYPES.TARGETS['INPUT_' + target.toUpperCase()]).append(this['$input' + target + 'Container'].children());
};

DatePickerMobile.prototype.insertBackButton = function() {
    var $backButtonInCloned = this.getContainer(TYPES.TARGETS.INPUT_IN).find('.cancelBtn').clone(true);
    var $backButtonOutCloned = this.getContainer(TYPES.TARGETS.INPUT_OUT).find('.cancelBtn').clone(true);

    this.getContainer(TYPES.TARGETS.INPUT_IN).find('.datepicker__back').prepend($backButtonInCloned);
    this.getContainer(TYPES.TARGETS.INPUT_OUT).find('.datepicker__back').prepend($backButtonOutCloned);
};

DatePickerMobile.prototype.loadEvents = function() {
    DatePickerCore.prototype.loadEvents.call(this);

    this.$events.onDatePickerShow = this.onDatePickerShow.bind(this);
    this.$events.onDatePickerHide = this.onDatePickerHide.bind(this);

    this.$inputIn.on(TYPES.EVENTS.SHOW, this.$events.onDatePickerShow);
    this.$inputIn.on(TYPES.EVENTS.HIDE, this.$events.onDatePickerHide);
    this.$inputOut.on(TYPES.EVENTS.SHOW, this.$events.onDatePickerShow);
    this.$inputOut.on(TYPES.EVENTS.HIDE, this.$events.onDatePickerHide);
};

DatePickerMobile.prototype.removeEvents = function() {
    DatePickerCore.prototype.removeEvents.call(this);

    this.$inputIn.off(TYPES.EVENTS.SHOW, this.$events.onDatePickerShow);
    this.$inputIn.off(TYPES.EVENTS.HIDE, this.$events.onDatePickerHide);
    this.$inputOut.off(TYPES.EVENTS.SHOW, this.$events.onDatePickerShow);
    this.$inputOut.off(TYPES.EVENTS.HIDE, this.$events.onDatePickerHide);
}

DatePickerMobile.prototype.onDatePickerShow = function(event, picker) {
    DatePickerCore.prototype.onDatePickerShow.call(this, event, picker);
    $(TYPES.SELECTORS.WRAPPER).show();
    $(TYPES.SELECTORS.WRAPPER).scrollTop('0');
    picker.container.parent().show();
    this.$inputIn.blur();
    this.$inputOut.blur();
};

DatePickerMobile.prototype.onDatePickerHide = function(event, picker) {
    DatePickerCore.prototype.onDatePickerHide.call(this, event, picker);

    var TARGET_KEY = 'INPUT_' + (event.target === this.$inputOut.get(0) ? 'OUT' : 'IN');

    $(TYPES.SELECTORS.WRAPPER).hide();
    this.setMobileContainer(TYPES.TARGETS.INPUT_OUT);
    this.getContainer(TYPES.TARGETS[TARGET_KEY]).hide();
};

module.exports = DatePickerMobile;
