window.jQuery = window.$ = require('jquery');
require('jquery-mask-plugin');
var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./utils/webviewCheck'));
    processInclude(require('./utils/mobileCheck'));
    processInclude(require('./utils/deviceCheck'));
    processInclude(require('./utils/transformDateAndCalculateDays'));
    processInclude(require('institucionais/setVariables/setVariables'));
    processInclude(require('institucionais/sendEmail'));
    processInclude(require('institucionais/searchBar/searchBar'));
    processInclude(require('institucionais/slider/slider'));
    processInclude(require('institucionais/gallery/gallery'));
    processInclude(require('institucionais/faq/faq'));
    processInclude(require('institucionais/datepicker/datepicker'));
    processInclude(require('institucionais/header/header'));
    processInclude(require('institucionais/progress/progress'));
    processInclude(require('institucionais/destinationPage'));
    processInclude(require('institucionais/pdv'));
    processInclude(require('./masks'));
    processInclude(require('../../../../../app_comporte_base/cartridge/client/default/js/components/searchTicketsLoading'));
    processInclude(require('institucionais/charter/charter'));
    processInclude(require('../../../../../app_comporte_base/cartridge/client/default/js/reservamos/reservamos'));
});

require('base/thirdParty/bootstrap');
require('base/components/spinner');
require('daterangepicker');
