var utils = require('./datepicker.utils');

var isBackDateValid = function(inputIn, inputOut) {
    var dateDeparture = $(inputIn).val();
    var dateBack = $(inputOut).val();

    return dateBack
        ? utils.getMillisecondsFromPTBRDate(dateBack) >= utils.getMillisecondsFromPTBRDate(dateDeparture)
        : !dateBack;
};

module.exports = {
    isBackDateValid: isBackDateValid
}