var CLASSES = {
    AUTO_APPLY: 'auto-apply'
};

var TARGETS = {
    INPUT_IN: 'In',
    INPUT_OUT: 'Out',
};

var EVENTS = {
    CANCEL: 'cancel.daterangepicker',
    SHOW: 'show.daterangepicker',
    HIDE: 'hide.daterangepicker',
    APPLY: 'apply.daterangepicker',
    HIDE_CALENDAR: 'hideCalendar.daterangepicker'
};

var SELECTORS = {
    IN_CONTAINER: '[data-datepicker="In"]',
    IN_CONTAINER_MOBILE: '[data-datepicker-mobile="In"]',
    OUT_CONTAINER: '[data-datepicker="Out"]',
    OUT_CONTAINER_MOBILE: '[data-datepicker-mobile="Out"]',
    WRAPPER: '.datepicker__wrapper'
};

module.exports = {
    CLASSES,
    TARGETS,
    EVENTS,
    SELECTORS
};