window.transformDateAndCalculateDays = function (dateStr) {
    if (!/^\d{2}\/\d{2}\/\d{4}$/.test(dateStr)) {
        throw new Error("Invalid date format. Please use 'DD/MM/YYYY'.");
    }

    const [day, month, year] = dateStr.split('/').map(Number);
    const initialDate = new Date(year, month - 1, day);

    if (isNaN(initialDate)) {
        throw new Error("Invalid date components. Please check the input.");
    }

    const newDate = new Date(initialDate);

    const formattedNewDate = newDate.toISOString().split('T')[0];

    const today = new Date();
    const diffInMilliseconds = newDate - today;
    const daysRemaining = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));

    return {
        formattedNewDate,
        daysRemaining: daysRemaining < 0 ? 0 : daysRemaining
    };
};
